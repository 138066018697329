section,
section.row {
  margin-bottom: 25px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 88px 0;
  }
}

@include media-breakpoint-up(lg) {
  .section-box {
    padding-left: 95px;
    padding-right: 95px;
  }
}

.section--sparks {
  z-index: 2;
  overflow: hidden;
  padding-top: 200px;
  &::before {
    content: url('/content-block-spark-mobile.svg');
    height: 211px;
    width: 200px;
    top: 0px;
    left: 0px;
    z-index: 1;
    position: absolute;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      content: url('/content-block-spark.svg');
      height: 232px;
      width: auto;
      bottom: 70px;
      top: auto;
      left: 83%;
    }
  }
}

.c-content-block__image > img {
  object-fit: cover;
}

.section--bg {
  &-grey {
    background-color: $tuna-gray-20;
  }

  &-splitYellowLeft {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      .c-content-block {
        display: flex;
        flex-direction: row;
        max-height: 496px;
      }
      .c-content-block__text-wrapper {
        width: 50%;
      }
    }

    @include media-breakpoint-up(lg) {
      .btn-link.bg-white {
        color: $purple;
      }
    }

    .c-content-block--media-align-left,
    .c-content-block--media-align-top,
    .c-content-block--media-align-right {
      .c-content-block__image {
        background-color: transparent;
        background-image: linear-gradient(
          to left,
          transparent,
          transparent 50%,
          $yellow 50%
        );
      }
    }

    @include media-breakpoint-up(lg) {
      .btn-link.bg-white {
        color: inherit;
      }
      .c-content-block {
        background-image: none;
      }
      background-color: transparent;
      background-image: linear-gradient(
        to right,
        $yellow,
        $yellow 25%,
        transparent 25%
      );
    }
  }
  &-splitYellowRight {
    margin-bottom: 30px;
    margin-top: 30px;

    @include media-breakpoint-up(md) {
      .c-content-block {
        display: flex;
        flex-direction: row-reverse;
        max-height: 496px;
      }
      .c-content-block__text-wrapper {
        width: 50%;
      }
    }

    @include media-breakpoint-up(lg) {
      .btn-link.bg-white {
        color: $purple;
      }
      .c-content-block {
        display: flex;
        flex-direction: row;
      }
    }
    .c-content-block--media-align-left,
    .c-content-block--media-align-top,
    .c-content-block--media-align-right {
      .c-content-block__image {
        background-color: transparent;
        background-image: linear-gradient(
          to right,
          transparent,
          transparent 50%,
          $yellow 50%
        );
      }
    }

    @include media-breakpoint-up(lg) {
      background-color: transparent;
      background-image: linear-gradient(
        to right,
        transparent,
        transparent 75%,
        $yellow 75%
      );
    }
  }

  &-splitYellowRight,
  &-splitYellowLeft {
    @include media-breakpoint-up(lg) {
      .c-content-block--media-align-left,
      .c-content-block--media-align-top,
      .c-content-block--media-align-right {
        .c-content-block__image {
          background-color: transparent;
          background-image: none;
          padding: 0;
        }
      }
    }
  }
}
