.c-navbar__functional-nav {
  @include media-breakpoint-up(lg) {
    margin-left: 4rem;
    gap:48px;
  }
  .nav-item {
    padding: 0;
  }

  .c-navbar .navbar-collapse.show & &-login {
    border-bottom: none;
    a {
      background-color: white;
      color: $orange;
      border-radius: 4px;
      justify-content: center;
      height: auto;
      padding: 0.5rem 1rem;
    }
  }

  &-button {
    &--orange.btn-primary.btn-primary:not(:disabled):not(.disabled) {
      & + & {
        margin-top: 0.5rem;
      }
      @include media-breakpoint-up(lg) {
        & + & {
          margin-top: 0;
          margin-left: 0.5rem;
        }
      }
      padding: 0.5rem 1rem;
      &:hover,
      &:focus {
        background-color: darken($orange, 10%);
        outline: none;
        box-shadow: none;
      }
      &:active {
        background-color: darken($orange, 20%);
        outline: none;
        box-shadow: none;
      }
      a {
        line-height: 2rem;
        font-size: 1.125rem;
        padding: 0;
      }
    }
    &--search,
    &--submit-search {
      padding: 0.5rem;
      width: 3rem;
      height: 3rem;
      position: relative;
      box-shadow: none;
      justify-content: center;
      align-items: center;
      &::before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.028 16.805a7.778 7.778 0 1 0 0-15.555 7.778 7.778 0 0 0 0 15.555ZM18.75 18.75l-4.229-4.23' stroke='%23303642' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        background: $tuna-gray-80;
      }
    }

    &--submit-search {
      background-color: $white;
      box-shadow: none;
      border: 1px solid $tuna-gray-40;
      border-right: none;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        background: $white;
      }
    }
  }

  &-search {
    margin-top: 4rem;
    @include media-breakpoint-up(lg) {
      &:not(.mobile) {
        margin-right: 4rem;
      }
    }
    margin-right: 4rem;
    .input-group {
      height: 3rem;
      input {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &:focus {
          box-shadow: none;
          border-color: #ced4da;
          border-left: 0;
        }
      }
    }
  }

  .btn-primary:not(:disabled):not(.disabled) {
    &:active,
    &:focus,
    &:hover {
      background-color: $tuna-gray-40;
      border-color: $tuna-gray-40;
      box-shadow: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    .nav-item {
      margin: 0;
    }
    .c-navbar__functional-nav-button--orange {
      margin: 0;
    }
    &.ml-auto.h-100.align-items-center.navbar-nav {
      padding-top: 0;
    }
  }
}
