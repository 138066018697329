.c-top-navigation {
  background: #f1f3f5;
  padding: 12px 16px;
  z-index: 1002;
  @include media-breakpoint-up(lg) {
    padding: 8px 88px;
  }
}

.ctn-audience-link {
  font-size: 12px;
  line-height: 16px;
  color: $tuna-gray;
  margin-right: 8px;
  @include media-breakpoint-up(lg) {
    font-size: 14px;
    line-height: 24px;
    margin-right: 24px;
  }
  &.active {
    &:after {
      content: '';
      height: 2px;
      width: 100%;
      background: red;
      display: block;
      top: calc(100% + 6px);
      position: absolute;
    }
  }

  &:active,
  &:hover {
    color: $slate-gray-60;
  }
}
.ctn-lang-switch {
  &__link {
    user-select: none;
  }
  button {
    color: $tuna-gray;
    border: none;
    background: none;
    appearance: none;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.125 5.5L.25 1.728 1.255.75l2.87 2.794L6.995.75 8 1.728 4.125 5.5z' fill='%23E4003A'/%3E%3C/svg%3E");
    width: 42px;
    background-repeat: no-repeat;
    background-position: right center;
    font-size: 12px;
    line-height: 1rem;
    box-shadow: none;
    padding: 0;
    @include media-breakpoint-up(lg) {
      font-weight: bold;
      font-size: 14px;
      margin-left: 0.5rem;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      border-color: transparent !important;
      color: $tuna-gray !important;
    }
  }
  .dropdown-toggle:after {
    content: '';
    border: none;
  }
  .dropdown-menu {
    padding: 0;
    min-width: auto;
    .dropdown-item {
      padding: 0 1rem;
    }
  }
}
