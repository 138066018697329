$section: '.c-in-the-picture-section';
$block: '.c-reference-item';

@mixin verticalLayout {
  #{$block} {
    display: flex;
    align-items: flex-start;
    &__image {
      &-container {
        width: 37.5%;
      }
    }
    &__content {
      flex: 1;
    }

    #{$block}__image-container + #{$block}__content {
      padding-left: 40px;
    }
  }
}

#{$section} {
  @include media-breakpoint-up(xl) {
    padding-left: 100px;
    padding-right: 100px;
  }

  &--layout {
    &-vertical {
      @include media-breakpoint-up(md) {
        @include verticalLayout();
      }
    }

    &-horizontal {
      @include media-breakpoint-only(md) {
        @include verticalLayout();
      }
    }
  }
}

#{$block} {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;

  &__image {
    &-container {
      position: relative;
    }

    @supports (object-fit: cover) {
      > div {
        height: 0;
        padding-bottom: 56.25%;
      }
    }
  }

  &__date {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $slate-gray-60;
    margin-bottom: 0.5rem;
  }

  &__tag {
    bottom: 8px;
    z-index: 1;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 5px 5px 5px 10px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $purple;
      z-index: -1;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      right: -10px;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 28px 0 0 10px;
      border-color: transparent transparent transparent $purple;
    }
  }
}
