.c-breadcrumb {
  margin-bottom: 0;

  &__spark::before {
    display: none;
    @include media-breakpoint-up(xl) {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='296' height='280' viewBox='0 0 296 280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8043 -2.02407e-05L252.331 263.906L232.351 280L-0.000123531 16.7133L20.8043 -2.02407e-05Z' fill='white'/%3E%3Cpath d='M116.176 256.271L92.4875 251.525L146.25 32.1885L170.762 37.1406L116.176 256.271Z' fill='white'/%3E%3Cpath d='M296 104.819L16.6848 182.815L12.5651 157.023L291.674 77.3765L296 104.819Z' fill='white'/%3E%3Cg style='mix-blend-mode:multiply'%3E%3Cpath d='M20.8043 -2.02407e-05L252.331 263.906L232.351 280L-0.000123531 16.7133L20.8043 -2.02407e-05Z' fill='%23F8AD07'/%3E%3C/g%3E%3Cg style='mix-blend-mode:multiply'%3E%3Cpath d='M116.176 256.271L92.4875 251.525L146.25 32.1885L170.762 37.1406L116.176 256.271Z' fill='%23E4003A'/%3E%3C/g%3E%3Cg style='mix-blend-mode:multiply'%3E%3Cpath d='M296 104.819L16.6848 182.815L12.5651 157.023L291.674 77.3765L296 104.819Z' fill='%23870B58'/%3E%3C/g%3E%3C/svg%3E%0A");
      height: 280px;
      width: 296px;
      position: absolute;
      display: block;
      right: 25%;
      top: -50%;
      z-index: 99;
    }
  }

  ol {
    list-style: none;
    padding-left: 0;
    display: none;

    .nav-link {
      color: $slate-gray;
    }

    a,
    a:hover {
      color: $slate-gray;
      font-weight: normal;
    }

    span {
      font-size: 0.875rem;
      margin-top: 3px;
      color: $slate-gray;
    }

    li:first-of-type a {
      padding-left: 0;
      position: relative;
      padding-left: 35px;

      &::before {
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzg5OGU5YSI+PHBhdGggZD0iTTg2LDE1LjA0NzJsLTc4LjgzMzMzLDcwLjk1MjhoMjEuNXY2NC41aDUwLjE2NjY3di00M2gxNC4zMzMzM3Y0M2g1MC4xNjY2N3YtNjQuNWgyMS41ek04NiwzNC4zMzU2MWw0MywzOC43MDI4djUuNzk0OTJ2NTcuMzMzMzNoLTIxLjV2LTQzaC00M3Y0M2gtMjEuNXYtNjMuMTI4MjZ6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4=');
        display: inline-block;
        position: absolute;
        left: 0;
        margin-right: 15px;
        top: 10px;
        text-decoration: none;
      }
    }

    li:last-of-type span {
      font-weight: bold;
      color: $tuna-gray-80;
    }

    li:not(:last-of-type):hover span[itemprop='name'] {
      text-decoration: underline;
    }

    .c-breadcrumb a:first-child {
      padding-left: 5px;
    }

    span.c-breadcrumb__delimiter {
      background-color: $tuna-gray-80;
      width: 1px;
      height: 25px;
    }
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}
