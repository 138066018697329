.c-consultant {
  background-color: white;
  padding: 2rem;
  &-expertiseWrapper {
    width: 100%;
    @include media-breakpoint-up(lg) {
      max-width: 180px;
    }

    & span {
      background-color: $slate-gray;
      font-size: 0.75rem;
    }
  }
  &-section {
    background-color: $purple;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  &-wrapper {
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      column-gap: 0;
    }
  }
  &-title {
    font-size: 18.8458px;
    font-size: $headings-font-family;
  }

  &-job {
    font-size: 15.4193px;
  }

  &-image {
    img {
      border-radius: 50%;
    }
    margin-right: 1rem;
  }

  &-phone,
  &-email,
  &-linkedin {
    font-weight: 400;
    font-size: 0.75rem;
    color: $tuna-gray-80;
    word-break: break-all;
  }
  &-iconWrapper {
    width: 2rem;
  }
  &-fieldWrapper {
    display: flex;
    align-items: center;
  }
}
