.c-person-list {
  margin: 2rem 0;
  &__person {
    margin: 1.5rem 0;
    width: 20%;
    @include media-breakpoint-down(lg) {
      width: 33%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  &__person-image {
    padding: 2rem;
    max-width: 250px;
    max-height: 250px;
  }
  &__person-name {
    font-size: 1rem;
  }
  &__person-job-title {
    color: $tuna-gray-80;
    font-size: 0.9rem;
  }
  &__person-company {
    color: $tuna-gray-80;
    font-size: 0.9rem;
  }
}
