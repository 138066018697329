$block: '.c-article-content-block';

#{$block} {
  padding-top: 30px;
  padding-bottom: 30px;

  .col-xl-8.col-lg-12.offset-xl-2 & {
    margin-bottom: 25px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 100px;
    }
  }

  &--bg {
    &-none,
    &-grey {
      h3 {
        color: $black;
      }
      h4 {
        color: $slate-gray;
      }
    }

    &-orange,
    &-purple,
    &-red,
    &-darkGrey {
      h3 {
        color: $white;
      }
      color: $white;
      .rich-text a {
        color: $white;
        &:hover {
          color: $slate-gray-60;
        }
      }
    }

    &-orange,
    &-purple,
    &-red,
    &-grey,
    &-darkGrey {
      padding: 30px;
    }

    &-orange,
    &-red,
    &-purple,
    &-grey,
    &-darkGrey {
      &#{$block}--sparks {
        position: relative;
        margin-top: 60px;
        margin-bottom: 60px;
        padding: 60px 30px;

        @include media-breakpoint-up(xl) {
          padding: 60px;
        }
      }

      .btn--white {
        color: $purple;
      }
    }

    &-orange,
    &-red {
      &#{$block}--sparks {
        &::before {
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg width='47' height='72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 61.693L16.242 72l30.037-61.462L29.639 0 0 61.693z' fill='%23870B58'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          width: 47px;
          height: 72px;
          content: '';
          top: -30px;
          left: 30px;
          z-index: 2;

          @include media-breakpoint-up(xl) {
            left: 70px;
          }
        }

        &::after {
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg width='65' height='72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.302 0l49.545 65.831L50.46 72 0 6.555 15.302 0z' fill='%23F8AD07'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          width: 65px;
          height: 72px;
          content: '';
          bottom: -30px;
          right: 30px;
          z-index: 2;

          @include media-breakpoint-up(xl) {
            right: 80px;
          }
        }
      }
    }

    &-purple,
    &-grey {
      &#{$block}--sparks {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='47' height='72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 61.693L16.24 72l30.037-61.462L29.638 0 0 61.693z' fill='%23E94E0F'/%3E%3C/svg%3E");
          content: '';
          position: absolute;
          top: -30px;
          right: 10%;
          width: 47px;
          height: 72px;
          background-repeat: no-repeat;
        }

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='66' height='72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.802 0l49.545 65.831L50.96 72 .5 6.555 15.802 0z' fill='%23F8AD07'/%3E%3C/svg%3E");
          content: '';
          position: absolute;
          bottom: -40px;
          left: 10%;
          width: 66px;
          height: 72px;
          background-repeat: no-repeat;
        }
      }
    }

    &-orange {
      background-color: $orange;
    }
    &-purple {
      background-color: $purple;
    }
    &-red {
      background-color: $red;
    }
    &-grey {
      background-color: $tuna-gray-20;
    }
    &-darkGrey {
      background-color: $tuna-gray;
    }
  }

  &--align {
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
      ol {
        list-style-position: inside;
        padding-left: inherit;
      }
    }
    &-right {
      text-align: right;
      ol {
        list-style-position: inside;
        padding-left: inherit;
      }
    }
  }

  &__iframe {
    iframe {
      width: 100%;
    }
  }

  &__video,
  &__image,
  &__description {
    margin-bottom: 1rem;
  }

  .video-wrapper {
    width: 100%;
  }
}
