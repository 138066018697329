.c-teaser-list {
  $card-width: calc(33% - 12px);

  $gap: 24px;

  &::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='179' height='176' viewBox='0 0 346 392' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M162.337 0.645268L141.037 275.852L181.056 275.207L203.971 0L162.337 0.645268Z' fill='%23E4003A'/%3E%3Cpath d='M31.6284 259.72L0 281.014L92.3032 392L124.577 370.384L31.6284 259.72Z' fill='%23870B58'/%3E%3Cpath d='M356.949 164.543L203.971 361.995L240.763 379.74L396 183.256L356.949 164.543Z' fill='%23F8AD07'/%3E%3C/svg%3E");
    height: 176px;
    display: block;
    position: absolute;
    width: 179px;
    right: 0px;
    top: 15px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__background-left,
  &__background-right {
    z-index: -1;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__background-left {
    top: 0;
    left: 0;
  }
  &__background-right {
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  padding-top: 230px;

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
  }

  &__heading {
    color: $tuna-gray;
    margin-bottom: 16px;
    font-size: 3rem;
    max-width: 761px;

    a {
      color: $purple;
      text-decoration: underline;
    }
  }

  &__short-description {
    max-width: 761px;
    margin-bottom: 48px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: $gap;

    @include media-breakpoint-up(lg) {
      grid-template-columns: $card-width;

      &--1 {
        grid-template-columns: $card-width;
      }

      &--2,
      &--4 {
        grid-template-columns: repeat(2, $card-width);
      }

      &--3,
      &--6,
      &--9 {
        grid-template-columns: repeat(3, $card-width);
      }

      &--5 {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        & > .c-teaser-list__cards--wrapper,
        .c-teaser-list__card {
          &:nth-child(4) {
            grid-column: 1 / 2;
            grid-row: 2;
            margin: 0 auto;
            transform: translateX(calc(50% + ($gap / 2)));
          }

          &:nth-child(5) {
            grid-column: 3 / 4;
            grid-row: 2;
            margin: 0 auto;
            transform: translateX(calc(-50% - ($gap / 2)));
          }
        }
      }

      &--7 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        & > .c-teaser-list__cards--wrapper,
        .c-teaser-list__card {
          &:nth-child(4) {
            grid-column: 1 / 2;
            grid-row: 2;
            margin: 0 auto;
            transform: translateX(calc(50% - ($gap / 2)));
          }

          &:nth-child(5) {
            grid-column: 3 / 4;
            grid-row: 2;
            margin: 0 auto;
            transform: translateX(calc(-50% + ($gap / 2)));
          }
          &:nth-child(6) {
            grid-column: 1 / 2;
            grid-row: 3;
            margin: 0 auto;
            transform: translateX(calc(50% - ($gap)));
          }

          &:nth-child(7) {
            grid-column: 3 / 4;
            grid-row: 3;
            margin: 0 auto;
            transform: translateX(calc(-50% + ($gap)));
          }
        }
      }

      &--8 {
        & > .c-teaser-list__cards--wrapper,
        .c-teaser-list__card {
          &:nth-child(7) {
            grid-column: 1 / 2;
            grid-row: 3;
            margin: 0 auto;
            transform: translateX(calc(50% - ($gap)));
          }

          &:nth-child(8) {
            grid-column: 3 / 4;
            grid-row: 3;
            margin: 0 auto;
            transform: translateX(calc(-50% + ($gap)));
          }
        }
      }
    }
  }

  &__card {
    background: #fff;
    box-shadow: 0px 8px 24px rgba(48, 54, 66, 0.16);
    border-radius: 8px;

    &-title {
      color: $red;
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    &-icon {
      width: 60px;
      height: 60px;
      margin-bottom: 18px;
    }

    &-text {
      font-size: 0.88rem;
      line-height: 1.33rem;
      color: $tuna-gray;
    }

    &-button {
      line-height: 2rem;
      width: max-content;
    }
  }
}
