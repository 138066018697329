.c-job-overview-two-panes {
  @include media-breakpoint-down(md) {
    padding-inline: 1rem;
  }

  &__desktop-filters {
    gap: 1.16rem;
  }

  &__error-modal {
    inset: 0;
    z-index: 1030;

    &-backdrop {
      background-color: rgba(0, 0, 0, 0.2);
      inset: 0;
      z-index: -1;
    }

    &-content {
      top: 5.625rem;
      margin-inline: 1rem;
      gap: 0.5rem;

      &--description {
        background-color: $white;
        border-radius: 0.57969rem;
        padding-block: 1rem;
        padding-inline: 0.5rem;
      }

      @include media-breakpoint-up(sm) {
        max-width: 500px;
        margin-inline: 6.5rem;
      }
    }
  }

  &__results {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: 4fr 6fr;
    }

    &--items {
      gap: 1.5rem;

      &-total {
        font-family: $font-family-sans-serif;
        font-size: 1.30431rem;
        font-weight: 700;
        padding-inline: 1.5rem;
        padding-block: 1rem;
        line-height: 2.31881rem;
        border-radius: 0.28988rem;
        background: $white;
        box-shadow: 0px 9.275px 27.825px 0px rgba(15, 17, 21, 0.1);
      }

      &-cards {
        gap: 1.5rem;
        transition: opacity 150ms ease;

        &-overlay {
          opacity: 0.7;
          inset: 0;
          z-index: 1;
          cursor: not-allowed;
        }
      }

      &-card {
        border-radius: 0.57969rem;
        border-width: 1px;
        border-style: solid;
        border-color: #e3e6ef;
        padding: 1.75rem;
        gap: 1.75rem;
        transition: box-shadow ease 200ms;
        background-color: $white;

        &[data-active='true'] {
          border-color: $yellow;
          background-color: $slate-gray-10;
        }

        &:not([data-active='true']):hover {
          box-shadow: 0px 4px 12px 0px rgba(48, 54, 66, 0.12);

          & .c-job-overview-two-panes__results--items-card-title {
            text-decoration: underline;
          }
        }

        &-header {
          gap: 1.5rem;
        }

        &-company-logo {
          width: 176px;
          height: 83px;
          border: 1px solid $tuna-gray-40;
        }

        &-sdworx-logo {
          width: 152px;
          height: 70px;
        }

        &-title {
          font-size: 1.375rem;
          word-break: break-word;

          @include media-breakpoint-up(lg) {
            font-size: 2rem;
          }
        }

        &-specifications {
          row-gap: 0.5rem;
          column-gap: 2rem;
        }

        &-description {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;

          @include media-breakpoint-up(md) {
            -webkit-line-clamp: 1;
          }
        }
      }
    }

    &--preview {
      $v-margin: 1rem;

      border-radius: 0.57969rem;
      background: $white;
      padding: 1.5rem 1rem;
      max-block-size: calc(100svh - $main-nav-xl-height - ($v-margin * 2));
      max-block-size: calc(100vh - $main-nav-xl-height - ($v-margin * 2));
      gap: 1.75rem;
      overflow-y: auto;
      top: calc($main-nav-xl-height + $v-margin);

      @include media-breakpoint-up(xl) {
        padding: 2.9rem;
      }

      &-header {
        gap: 1.5rem;
      }

      &--title {
        color: $tuna-gray;
        font-family: $headings-font-family;
        font-size: 2.8985rem;
        font-weight: 400;
        line-height: 2.8985rem;
        word-break: break-word;
      }

      &-company-logo {
        width: 176px;
        height: 83px;
        border: 1px solid $tuna-gray-40;
        background-color: $slate-gray-5;
      }

      &-sdworx-logo {
        width: 152px;
        height: 70px;
      }

      &-attributes {
        border-radius: 0.25rem;
        border: 1px solid #e3e6ef;
        background-color: $tuna-gray-20;
        gap: 0.5rem;

        &-container {
          gap: 0.5rem;
        }
        &-text {
          color: $tuna-gray-80;
          font-size: 0.87rem;
          font-family: $font-family-sans-serif;
          letter-spacing: 1px;
          line-height: 1rem;
        }
      }

      &-desktop-intro-box {
        border-radius: 0.25rem;
        border: 1px solid $slate-gray-40;
        padding: 1.5rem;

        &-container {
          &-separator {
            border-left: 1px solid $slate-gray-40;
            padding-left: 1.5rem;
          }
        }

        &-title {
          font-size: 14px;
          line-height: 24px;
          color: $orange;
        }

        &-text {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &-apply-button {
        word-break: break-word;
      }

      &-benefits {
        gap: 0.5rem;
        flex-direction: column;

        & .toptitle {
          word-break: break-word;
        }

        &-item {
          width: fit-content;
        }

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      &-salary {
        width: fit-content;
        margin-top: 0.5rem;
      }

      &-carousel {
        overflow-x: auto;
        overflow-y: hidden;
        gap: 1.25rem;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        @include media-breakpoint-up(lg) {
          padding-left: 20px !important;
          padding-right: 125px;
          padding-top: 160px;
        }

        &-item {
          overflow: hidden;
          box-shadow: 0px 8px 24px rgba(48, 54, 66, 0.16);

          &-badge {
            padding: 1rem;
            border-radius: 0.25rem;
            font-weight: bold;
          }

          .badge-yellow {
            background: $yellow;
            color: black;
          }
          .badge-purple {
            background: $purple;
            color: white;
          }
          .badge-red {
            background: $red;
            color: white;
          }
          .badge-orange {
            background: $orange;
            color: white;
          }

          &-image {
            min-height: 200px;
            max-height: 200px;
          }

          &-title {
            color: $gray;
          }

          &-text {
            line-height: 2rem;
          }
        }
      }

      &-office {
        gap: 1rem;
        word-break: break-word;

        &-details {
          gap: 1rem;
        }

        &-title {
          color: $tuna-gray-80;
          font-family: $headings-font-family;
          font-size: 1.25rem;
          font-weight: 700;
          @include media-breakpoint-up(md) {
            font-size: 1.75rem;
          }
        }

        &-detail {
          gap: 0.5rem;
          font-size: 1rem;
          font-weight: 400;
          color: $tuna-gray;
          @include media-breakpoint-up(md) {
            font-size: 1.25rem;
          }

          & > a {
            font-weight: 400 !important;
            color: $tuna-gray !important;

            &:hover,
            &:focus-within {
              text-decoration: underline !important;
            }
          }
        }

        &-button {
          width: fit-content;
          gap: 1rem;
        }

        &-map {
          min-height: 20rem !important;

          @include media-breakpoint-up(xl) {
            height: 100% !important;
          }

          &-container {
            min-height: 20rem;
            min-width: 10rem;
            width: 100%;
            max-width: 100%;

            @include media-breakpoint-up(xl) {
              width: 70%;
            }
          }
        }
      }

      &-application_steps {
        &-title {
          font-family: $font-family-sans-serif;
          color: $tuna-gray;
          font-size: 1.73906rem;
          font-weight: 500;
          word-break: break-word;
          flex-grow: 1;
        }
        &-buttons {
          gap: 0.6rem;
        }
        &-button {
          width: 44.057px;
          height: 44.057px;
          background-color: $slate-gray-10;
          border: none;
          border-radius: 9.441px;
          opacity: 1;

          &:disabled {
            opacity: 0.5;
          }
        }
      }

      &-unboxed {
        &-title {
          font-family: $font-family-sans-serif;
          color: $tuna-gray;
          font-size: 1.73906rem;
          font-weight: 500;
          word-break: break-word;
        }

        &-text {
          word-break: break-word;
        }
      }

      &-boxed {
        padding: 1rem;
        background-color: $slate-gray-5;
        border: 1px solid #e3e6ef;
        border-radius: 0.28988rem;

        &-title {
          font-family: $headings-font-family;
          font-size: 2.15rem;
          font-weight: 300;
          word-break: break-word;
        }

        &-text {
          word-break: break-word;
        }
      }
    }

    &--modal {
      inset: 0;
      z-index: 1030;

      &-backdrop {
        background-color: rgba(0, 0, 0, 0.2);
        inset: 0;
        z-index: -1;
      }

      &-content {
        max-height: calc(100vh - 2rem);
        max-height: calc(100svh - 2rem);
        gap: 0.5rem;
        border-radius: 0.57969rem;
        background: $white;
        box-shadow: 0px 9.275px 27.825px 0px rgba(48, 54, 66, 0.16);

        &-filters {
          border-radius: 1.15938rem;
        }

        & > .c-job-overview-two-panes__results--preview-office-map-container {
          width: 100%;
        }
      }

      &-close-icon {
        padding: 1.25rem 1.25rem 0rem 0rem;
      }
    }
  }

  &__application-form {
    &--modal {
      inset: 0;
      z-index: 1040;
      padding-block: 2rem 1rem;
      padding-inline: 0.5rem;

      &-backdrop {
        background-color: rgba(0, 0, 0, 0.2);
        inset: 0;
        z-index: -1;
      }

      &-content {
        max-height: calc(100vh - 2rem);
        max-height: calc(100svh - 2rem);
        background-color: #ffffff;
        border-radius: 0.57969rem;
      }

      &-close-icon {
        top: 1.25rem;
        right: 1.25rem;
      }

      &-form-details {
        padding: 4.5rem 3rem 3rem;
        margin: 0;
        gap: 1.15rem;

        @include media-breakpoint-up(md) {
          gap: 2rem;
        }
        @include media-breakpoint-up(lg) {
          width: 50rem;
        }
      }

      &-title {
        color: $purple;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 300;
        margin: 0;

        @include media-breakpoint-up(xl) {
          line-height: 2.3rem;
        }
      }
    }
  }
}

.desktop-filter {
  &__control {
    cursor: pointer !important;
    padding: 0.57969rem 0.86956rem 0.57969rem 1.15938rem;
    flex-wrap: nowrap !important;

    &--menu-is-open {
      background-color: #c8cdd9 !important;
      border: 1.159px solid #adb4c6 !important;

      .desktop-filter__indicators {
        transform: rotateX(180deg);
        transition: transform 0.5s linear;
      }
    }
  }

  &__menu {
    padding-inline: 1.16rem;
    padding-block-end: 1.16rem;
  }

  &__option {
    gap: 1.16rem;
    cursor: pointer !important;
  }

  &__input {
    cursor: pointer !important;
  }
}

.react-multi-carousel-item {
  max-height: 430px;
  margin: 0.5rem;
}
