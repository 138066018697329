.c-hero {
  border-top: 1px solid $slate-gray-20;
  &__button {
    display: flex;
    align-items: center;
    position: relative;
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 -3 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5148 3.51465L22.0002 11.9999L13.5149 20.4852L12.1007 19.071L18.1718 12.9999H2V10.9999H18.1717L12.1006 4.92886L13.5148 3.51465Z' fill='%23F9D2C3'/%3E%3C/svg%3E");
      display: inline-block;
      margin-left: 15px;
    }
  }
  &__intro {
    padding-top: 88px;
    padding-bottom: 88px;
    h1 {
      margin: 1.5rem 0;
      max-width: 650px;
    }

    &-description {
      font-weight: 400;
      font-size: 1;
      line-height: 1.5rem;
      max-width: 650px;
      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 2rem;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &-image {
      margin-bottom: 2rem;
    }
  }

  &__image {
    display: none;
    &-content-page {
      &--mobile {
        img {
          height: 350px;
          width: 100%;
        }
      }
    }
    &--mobile {
      margin-right: -$grid-gutter-width / 2 !important;
    }
  }

  &__employer-detail {
    position: relative;
    &__image-wrapper {
      min-height: 350px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-hero {
    &__intro {
      &-subtitle {
        font-size: 1.5rem;
      }
    }
    &__employer-detail {
      position: absolute;
      &__image-wrapper {
        min-height: 400px;
      }
    }
  }
}
