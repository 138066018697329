.c-application-form-two-panes {
  &__content {
    gap: 1.16rem;

    &__fields {
      gap: 1.16rem;
      margin-bottom: 1.16rem;

      &__country-zipcode {
        gap: 1.16rem;
        flex-direction: column;

        &__country {
          padding: 13px 16px !important;
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }
    }

    &__consent-text {
      font-size: 0.875rem;
    }

    &__privacy-text a,
    &__consent-text a {
      color: $purple;
      text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
      gap: 2.4rem;
    }
  }

  &__final-step-modal {
    inset: 0;
    z-index: 1050;
    padding-inline: 1.8rem;

    &-backdrop {
      background-color: rgba(0, 0, 0, 0.2);
      inset: 0;
      z-index: -1;
    }

    &-content {
      max-height: calc(100vh - 2rem);
      max-height: calc(100svh - 2rem);
      background-color: #ffffff;
      border-radius: 0.57969rem;
      padding: 2rem;

      @include media-breakpoint-up(xl) {
        max-width: 65rem;
      }
    }

    &-title {
      color: $slate-gray;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6rem;
      max-width: 30rem;

      @include media-breakpoint-up(md) {
        font-size: 2.6rem;
        line-height: 2.6rem;
      }
    }

    &-description {
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;

      &-success {
        max-width: 15rem;
        @include media-breakpoint-up(md) {
          max-width: 22.6rem;
        }
      }

      &-error {
        max-width: 46rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }

    &-button {
      width: 9rem;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        width: 15rem;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      padding-inline: 4rem;
    }
  }
}
