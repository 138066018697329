.location-block {
  background-color: $white;
  @include media-breakpoint-up(lg) {
    background-color: $slate-gray-10;
  }

  &__map-wrapper {
    height: 672px;

    @include media-breakpoint-up(lg) {
      height: auto;
    }
  }
}
