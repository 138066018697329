@import url('https://use.typekit.net/jyh7sik.css');

@import './theme/sdworx-bootstrap.scss';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
// @import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
// @import 'bootstrap/scss/images';
/* @import "code"; */
@import 'bootstrap/scss/grid';
/* @import 'bootstrap/scss/tables'; */
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
// @import "button-group";
@import 'bootstrap/scss/input-group';
/* @import 'bootstrap/scss/custom-forms'; */
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
/*
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
*/
@import 'bootstrap/scss/media';
/*
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "popover";*/
/* @import 'bootstrap/scss/carousel'; */
/*
@import "spinners";
*/
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/print';

@import './theme/section.scss';

@import './components/buttons.scss';
@import './components/navbar.scss';
@import './components/footer.scss';
@import './components/hero.scss';
@import './components/content-block.scss';
@import './components/in-the-picture.scss';
@import './components/doormat.scss';
@import './components/breadcrumb.scss';
@import './components/functional-nav.scss';
@import './components/navigation-highlight.scss';
@import './components/blog-article.scss';
@import './components/article-content-block.scss';
@import './components/person-list.scss';
@import './components/quote.scss';
@import './components/event-overview.scss';
@import './components/teaser-list.scss';
@import './components/text-and-contact.scss';
@import './components/resource-overview.scss';
@import './components/spotlight.scss';
@import './components/whitepaper.scss';
@import './components/top-navigation.scss';
@import './components/domain-slider.scss';
@import './components/expert.scss';
@import './components/news-block.scss';
@import './components/vision-block.scss';
@import './components/job-details.scss';
@import './components/forms.scss';
@import './components/job-overview.scss';
@import './components/office-page.scss';
@import './components/office-overview.scss';
@import './components/pagination.scss';
@import './components/consultants.scss';
@import './components/expert-overview.scss';
@import './components/employer-overview.scss';
@import './components/location-block.scss';
@import './components/logo-list.scss';
@import './components/newsletter.scss';
@import './components/open-application.scss';
@import './components/map.scss';
@import './components/language-switch.scss';
@import './components/job-search-link.scss';
@import './components/search-input.scss';
@import './components/500.scss';
@import './components/job-overview-two-panes.scss';
@import './components/application-form-two-panes.scss';
@import './components/salary.scss';
@import './components/sd-pagination.scss';

:root {
  $scroll-offset: 1rem;
  scroll-padding-top: calc($main-nav-xs-height + $scroll-offset);
  scroll-behavior: smooth;

  @include media-breakpoint-up(xl) {
    scroll-padding-top: calc($main-nav-xl-height + $scroll-offset);
  }
}

body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: 'nimbus-sans', sans-serif;
}

h1,
.h1 {
  font-size: 3rem;
  line-height: 3.5rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

main > section > h2 {
  @include media-breakpoint-up(lg) {
    margin-left: 8.3333333333%;
  }
}

a {
  color: $orange;
  font-weight: bold;
}

.rich-text {
  a {
    color: $orange;
    text-decoration: underline;
  }

  ul {
    list-style: none;
    li:before {
      margin-left: -27px;
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 20px;
      background-color: #f8ad07;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: inherit;
    font-weight: 700;
  }
}

a:hover,
a:active {
  color: $slate-gray-60;
  text-decoration: none;
}

.btn {
  border-radius: 4px;
}

.video-responsive {
  overflow: hidden;
}

.video-wrapper {
  width: 100%;
}

.bg-none {
  background: none;
}

.visually-hidden {
  visibility: hidden;
}

.tooltip {
  .tooltip-inner {
    padding: 0.25rem 0.5rem;
    background-color: $slate-gray-10;
    color: $tuna-gray;
    font-size: 0.75rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  .arrow::before {
    border-right-color: $slate-gray-10;
  }
  &-icon {
    margin: 0 10px;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-linejoin='round' d='M9 16.313A7.312 7.312 0 1 0 9 1.688a7.312 7.312 0 0 0 0 14.624Z'/%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-linejoin='round' d='M7.313 7.313A1.687 1.687 0 1 1 9 9v1.125'/%3E%3Cpath fill='%23000' d='M9 11.813a.563.563 0 1 0 .563.562.57.57 0 0 0-.563-.563Z'/%3E%3C/svg%3E");
    @include media-breakpoint-up(lg) {
      margin: 4px 10px 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .video-wrapper {
    width: 50%;
    .col-lg-8 & {
      width: 66.66%;
    }
    .col-lg-4 & {
      width: 100%;
    }
  }
}

.break-word {
  overflow-wrap: break-word;
}

.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  overflow: hidden;
  > * {
    margin: 0;
  }
}

.file-name {
  color: inherit;
}

.text-sm {
  font-size: 1rem;
}

.submitting-loader::before {
  content: '';
  border: 1px solid transparent;
  border-top: 2px solid #f8d107;
  border-radius: 50%;
  margin-right: 15px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

form input[type='checkbox'].no-translate {
  transform: none;
}

.opacity-0 {
  opacity: 0;
}

.w-0 {
  width: 0;
}

.text-tuna-gray-60 {
  color: $tuna-gray-60;
}

.bg-slate-gray-10 {
  background-color: $slate-gray-10;
}

.font-lighter {
  font-weight: 400;
}

.children-no-margin > * {
  margin: 0;
}

.bottom-0 {
  bottom: 0;
}

.text-tuna-gray-80 {
  color: $tuna-gray-80;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.max-w-100 {
  max-width: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.w-auto {
  width: auto;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-semibold {
  font-weight: 500;
}

.grid {
  display: grid;
}
.grid-cols-lg-3 {
  gap: 1.5rem;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

strong {
  font-weight: bold;
}
