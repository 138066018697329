.c-blog-article {
  &__container {
    max-width: 750px;
  }

  &-hero {
    background-color: #f1f3f5;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;

      &--image {
        width: 50%;
      }

      &--text {
        width: 50%;
        padding: 80px 108px;
        align-self: center;

        .c-blog-article__dateDuration {
          margin-top: 24px;

          .c-blog-article__author {
            font-size: 14px;
            line-height: 24px;
          }
          > time {
            font-size: 14px;
            line-height: 24px;
          }
          .c-blog-article__reading-time {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    &__title {
      margin: 0;
    }

    > .container-fluid {
      padding-block: 16px;

      @include media-breakpoint-up(md) {
        padding-block: 32px;
      }
    }
  }

  &__dateDuration {
    font-size: 0.8125rem;
    display: flex;
    flex-direction: row;

    > .container-fluid {
      padding-block: 16px;

      @include media-breakpoint-up(md) {
        padding-block: 32px;
      }
    }

    .c-blog-article__author {
      margin: 0;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: $slate-gray;
      margin-right: 8px;
    }
    > time {
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $slate-gray;
    }
    .c-blog-article__reading-time {
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $slate-gray;
      margin-bottom: 0;
      margin-left: 2px;
    }
  }

  &__person {
    &-outerWrapper {
      background: linear-gradient(90deg, #fcfcfc 0, #f6f6f6 100%);
    }
    &-wrapper {
      padding: 2rem;
    }
    &-text-wrapper {
      div:first-of-type {
        margin-bottom: 0.5rem;
      }
    }
    &-img-wrapper {
      max-width: 18rem;
      margin-bottom: 2.5rem;
      img {
        border-radius: 50%;
      }
    }
    &-text-name {
      margin-left: 0.25rem;
      font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
      &-wrapper {
        padding: 8rem;
        margin: 1.5rem 8rem;
      }
      &-text-wrapper {
        padding-left: 8rem;
        padding-right: 8rem;
      }
      &-img-wrapper {
        max-width: 8rem;
      }
    }
  }

  .c-heading__wrapper {
    padding: 0;
  }
}
