.c-quote {
  background-color: $tuna-gray-20;
  padding: 3rem 0;

  @include media-breakpoint-up(md) {
    padding: 5rem 0rem 3rem;
  }
  &__person {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
    &-spark {
      @include media-breakpoint-up(md) {
        right: 20rem;
        top: -3rem;
        left: 0rem;
        left: unset;
        .col-lg-8 & {
          display: none;
        }
      }
    }
    &-image {
      max-width: 300px;
      max-height: 300px;
      &--rounded {
        border-radius: 50%;
      }
    }
    &-title {
      margin: 2rem 0;
      font-size: 1.5rem;
      color: $tuna-gray-80;
    }
  }
  &__text {
    line-height: 1.1;
    margin-top: 2rem;
    font-size: 2rem;
    color: $tuna-gray-80;
    font-family: $headings-font-family;
    font-weight: 700;
    max-width: 1000px;
    &::before {
      content: '“';
    }
    &::after {
      content: '”';
    }
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
}
