.c-open-application {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  @include media-breakpoint-up(lg) {
    padding-top: 8.5rem;
    padding-bottom: 5rem;
  }
  &__form-group-wrapper {
    row-gap: 1rem;
  }
  &__form-group {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__upload-btn {
    border: 1px solid $tuna-gray-40;
    background-color: $tuna-gray-20;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: black;
    }
  }

  &__consent-text {
    font-size: 0.875rem;
    margin-left: 1.75rem;
  }

  &__privacy-text a,
  &__consent-text a {
    color: $purple;
    text-decoration: underline;
  }

  &__error,
  &__success {
    height: 240px;
  }
  &__submit-btn {
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 -3 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5148 3.51465L22.0002 11.9999L13.5149 20.4852L12.1007 19.071L18.1718 12.9999H2V10.9999H18.1717L12.1006 4.92886L13.5148 3.51465Z' fill='%23F9D2C3'/%3E%3C/svg%3E");
      display: inline-block;
      margin-left: 15px;
    }
  }

  &-title {
    font-size: 2rem;

    @include media-breakpoint-up(xl) {
      font-size: 3rem;
      padding-top: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 3.5rem;
      padding-top: 3rem;
    }
  }
}
