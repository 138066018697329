.expert-block {
  &__image::after {
    content: '';

    @include media-breakpoint-up(lg) {
      background-image: url("data:image/svg+xml,%3Csvg width='215' height='226' viewBox='0 0 215 226' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' d='M51.0168 219.886L72.0169 127.053L88.7111 132.896L68.5229 226L51.0168 219.886Z' fill='%23870B58'/%3E%3Cpath d='M0.294556 10.5839L12.433 0L76.7732 76.2383L64.2781 86.9874L0.294556 10.5839Z' fill='%23870B58'/%3E%3Cpath opacity='0.6' d='M109.431 90.0958L211.122 53.5275L214.295 70.488L112.844 108.13L109.431 90.0958Z' fill='%23870B58'/%3E%3C/svg%3E%0A");
      height: 215px;
      width: 226px;
      position: absolute;
      right: -14%;
      top: -40px;
    }
  }
  &__text {
    line-height: 2rem;
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  &__info {
    @include media-breakpoint-up(lg) {
      padding: 15% 0;
    }
  }

  &__person-info {
    bottom: 0;
    left: 0;
    width: 75%;
    padding: 8px 14px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      right: -20px;
      bottom: 0;
      width: 30px;
      height: 100%;
      transform: skew(20deg);
      background: $purple;
    }
  }
}
