.newsletter {
  position: relative;

  @include media-breakpoint-up(lg) {
    &::before {
      content: '';
      background-color: $yellow;
      width: 20vw;
      display: block;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &__image {
    min-height: 350px;
    display: grid;
    &__wrapper {
      height: 75%;
    }
    &::before {
      content: '';
      background-color: $yellow;
      width: 28vw;
      display: block;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
    @include media-breakpoint-up(lg) {
      height: auto;
      &__wrapper {
        height: 100%;
      }
      &::before {
        display: none;
      }
    }
  }

  &__form {
    &__email {
      &::before {
        content: url("data:image/svg+xml,%3Csvg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.484 7.976L22.635 2.632V13.259L16.484 7.976ZM8.558 8.881L10.718 10.756C11.057 11.044 11.499 11.218 11.982 11.218H11.999H11.998H12.012C12.496 11.218 12.938 11.043 13.281 10.753L13.278 10.755L15.438 8.88L22.004 14.519H1.995L8.558 8.881ZM1.986 1.365H22.016L12.395 9.721C12.2868 9.80656 12.1529 9.85308 12.015 9.853H12.001H12.002H11.988C11.8496 9.85318 11.7152 9.80627 11.607 9.72L11.608 9.721L1.986 1.365ZM1.365 2.631L7.515 7.975L1.365 13.255V2.631ZM22.965 0.19C22.725 0.0700001 22.443 0 22.144 0H1.859C1.56916 6.9235e-05 1.28331 0.0675106 1.024 0.197L1.035 0.192C0.724573 0.345111 0.463148 0.581979 0.280254 0.875845C0.0973606 1.16971 0.000287348 1.50887 0 1.855L0 14.027C0.000529404 14.5196 0.196452 14.9919 0.54478 15.3402C0.893108 15.6885 1.36539 15.8845 1.858 15.885H22.141C22.6336 15.8845 23.1059 15.6885 23.4542 15.3402C23.8025 14.9919 23.9985 14.5196 23.999 14.027V1.855C23.999 1.128 23.58 0.498 22.97 0.195L22.959 0.19H22.965Z' fill='%23E94E0F'/%3E%3C/svg%3E");
        position: absolute;
        top: 50%;
        transform: translateY(-70%);
        left: 15px;
        width: 24px;
        height: 16px;
        display: block;
      }
    }
  }
}
