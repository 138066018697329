.c-text-and-contact {
  &__person-image {
    padding-left: 25px;
  }
  &__person-info {
    padding: 0 25px;
  }
  &__person-name {
    font-weight: 500;
  }
  &__person-contact {
    @include media-breakpoint-up(md) {
      position: absolute;
      left: 33.33333%;
      margin-top: 1.5rem;
    }
  }
}
