.news-block {
  &__carousel {
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-overflow-scrolling: touch;

    &-wrapper {
      overflow-x: hidden;
      @include media-breakpoint-down(md) {
        padding-left: 25px;
      }
    }

    &__item {
      max-height: 392px;
      user-select: none;
      box-sizing: border-box;
      min-width: 80vw;
      width: 80vw;
      height: 70vw;
      max-height: 392px;
      border-radius: 8px;
      overflow: hidden;
      scroll-snap-align: start;
      box-shadow: 0px 8px 40px rgba(48, 54, 66, 0.24);

      @include media-breakpoint-down(md) {
        height: auto;
        max-height: none;
      }

      &__image {
        height: 220px;
      }
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 400;
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }
}

.news-resource-row {
  display: none;
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
  }
}

.news-resources-preview {
  &__animate {
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  &__image {
    min-height: 350px;
    max-height: 350px;
  }
  &__tag {
    color: $white;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 4px 10px;
    left: 0px;
    bottom: 0px;
    background: $red;

    &--blog_article {
      &::before {
        content: '';
        position: absolute;
        right: -10px;
        bottom: 0;
        width: 17px;
        height: 100%;
        transform: skew(20deg);
        background: $red;
      }
    }
  }

  &__description {
    font-size: 80%;
  }
}
