.c-whitepaper {
  &__title {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  &__related {
    &:hover {
      color: $tuna-gray-80;
      box-shadow: 0px 4px 4px rgba(8, 6, 6, 0.15);
    }
    &-container {
      margin-bottom: 2rem;
    }
    &-title {
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    &-image-wrapper {
      position: relative;
    }
    &-image-tag {
      position: absolute;
      z-index: 1;
      width: 100%;
      font-size: 0.75rem;
      font-weight: 500;
      color: $purple;
      text-transform: uppercase;
      letter-spacing: 1px;
      bottom: 7px;

      span {
        background-color: white;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        padding-left: 1rem;
        padding-right: 0.8125rem;
        display: inline-block;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: -10px;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 2rem 0 0 10px;
          border-color: transparent transparent transparent #fff;
        }
      }
    }
    &-description {
      color: $tuna-gray-80;
      p {
        margin-bottom: 0.5rem;
      }
    }
    &-publication-date-name-wrapper {
      color: $tuna-gray-80;
      font-size: 0.8125rem;
    }
    &-publication-date-name-separator {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
    &-name {
      color: $orange;
      font-weight: 700;
    }
    @include media-breakpoint-up(lg) {
      &-container {
        margin-bottom: 1rem;
      }
      &-title {
        margin-bottom: 4rem;
        margin-top: 4rem;
      }
      &-description {
        p {
          margin-bottom: 1rem;
        }
      }
    }
    &-wrapper-imageField {
      @supports (object-fit: cover) {
        > div {
          height: 0;
          padding-bottom: 56.25%;
        }
      }
    }
  }
}
