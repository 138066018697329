.c-office-overview {
  padding-top: $spacer * 2;

  &__searchWrapper {
    @include media-breakpoint-down(lg) {
      margin-bottom: 25px;
    }
  }

  &__search {
    &-title {
      color: $tuna-gray;
      font-weight: 700;
      font-size: 30px;
    }
    &-description {
      margin-bottom: 1rem;
      font-size: 13.9974px;
      line-height: 21px;
    }
    &-input-wrapper {
      position: relative;
      &:before {
        position: absolute;
        left: 10px;
        width: 13px;
        height: 12px;
        top: 13px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.81.375a4.376 4.376 0 00-4.37 4.37c0 1.046.367 2.006.983 2.758L.941 10.986l.628.628 3.483-3.482a4.336 4.336 0 002.758.983 4.376 4.376 0 004.37-4.37A4.376 4.376 0 007.81.375zm0 .874a3.49 3.49 0 013.496 3.496A3.49 3.49 0 017.81 8.24a3.49 3.49 0 01-3.496-3.496A3.49 3.49 0 017.81 1.249z' fill='%23303642'/%3E%3C/svg%3E");
        @include media-breakpoint-up(lg) {
          left: 18px;
          width: 21px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.04.75c-4.135 0-7.5 3.366-7.5 7.5 0 1.796.629 3.442 1.687 4.734L.25 18.961l1.078 1.078 5.977-5.977a7.442 7.442 0 004.734 1.688c4.134 0 7.5-3.366 7.5-7.5s-3.366-7.5-7.5-7.5zm0 1.5c3.321 0 6 2.678 6 6s-2.679 6-6 6a5.99 5.99 0 01-6-6c0-3.322 2.677-6 6-6z' fill='%23303642'/%3E%3C/svg%3E");
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &-input.form-control {
      border-radius: 3px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 32px;
      font-size: 0.75rem;
      height: auto;
      background-color: $slate-gray-10;
      border: none;
      &.error {
        border: 1px solid $orange;
      }
      &:focus {
        border: none;
        background-color: $slate-gray-10;
        box-shadow: 0 0 0 0.1rem rgb(0 0 0 / 75%);
      }
      @include media-breakpoint-up(lg) {
        padding: 20px 60px 20px 56px;
        font-size: 1rem;
      }
    }
    &-submit {
      position: relative;
      top: unset;
      right: unset;
      transform: none;
      width: 100%;
      padding: 8px 16px;
      font-size: 0.75rem;
      margin-top: 0.5rem;
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        width: auto;
        font-size: 1rem;
        margin-top: 0;
      }
    }
    &-use-my-location {
      background: none;
      border: none;
      color: $orange;
      padding: 0;
      font-size: 13.9974px;
      line-height: 21px;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
    &-show-all-results {
      background: none;
      border: none;
      color: $orange;
      font-size: 0.6rem;
      padding: 0;
      @include media-breakpoint-up(lg) {
        font-size: 0.75rem;
      }
    }
    &-btn-wrapper {
      margin-bottom: 2rem;
      position: relative;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
  &__map {
    height: 436px;
    &-selected-info {
      padding: 1.5rem;
      max-width: 30%;
      position: absolute;
      background-color: white;
      right: 10%;
      top: 50%;
      transform: translateY(-50%);
      &__address {
        color: $tuna-gray-80;
        font-size: 0.875rem;
      }
      &__close {
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.168 0 13 0zm0 2c6.086 0 11 4.914 11 11s-4.914 11-11 11S2 19.086 2 13 6.914 2 13 2zM9.219 7.78L7.78 9.22l3.782 3.78-3.782 3.782L9.22 18.22 13 14.437l3.781 3.782 1.438-1.438L14.437 13l3.782-3.781L16.78 7.78 13 11.562l-3.781-3.78z' fill='%23303642'/%3E%3C/svg%3E");
        height: 26px;
        width: 26px;
        display: block;
        border: 0;
        background-color: transparent;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
    }
  }
  &__offices-title {
    font-weight: 700;
    margin-bottom: 1rem;
    color: $tuna-gray;
    font-size: 22px;
    line-height: 26px;
    margin-top: 0;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      text-align: center;
      margin-bottom: 1.5rem;
      margin-top: 0;
    }
  }
  &__office-separator {
    display: block;
    height: 1px;
    width: 100%;
    background-color: $tuna-gray-40;
    margin: 0.5rem 0;
  }
  &__office-wrapper {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    @include media-breakpoint-up(lg) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &__office {
    box-shadow: 0 8px 24px rgb(15 17 21 / 10%);
    padding: 1.5rem;
    &-address-icon {
      height: 23px;
      width: 22px;
      display: inline-block;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.651.187A8.685 8.685 0 00.977 8.86a8.587 8.587 0 001.747 5.205s.237.311.276.356l6.651 7.845 6.655-7.848c.035-.042.273-.353.273-.353v-.002a8.583 8.583 0 001.747-5.203A8.685 8.685 0 009.651.187zm0 11.829a3.155 3.155 0 110-6.31 3.155 3.155 0 010 6.31z' fill='%23898E9A'/%3E%3C/svg%3E");
    }
    &-title {
      order: 2;
      @include media-breakpoint-up(lg) {
        order: 1;
      }
    }
    &-tags {
      gap: 0.5rem;
      order: 1;

      & > span {
        font-size: 0.75rem;
        line-height: 1rem;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        background-color: $slate-gray;
        border-radius: 2px;
        color: white;
      }
      @include media-breakpoint-up(lg) {
        order: 2;
        max-width: 50%;
      }
    }
    &-titleTagsWrapper {
      border-bottom: 1px solid #d8d8d8;
    }
    h5 {
      color: $tuna-gray;
      font-size: 22px;
      line-height: 26px;
      margin-top: 0;
      &:hover {
        color: $orange-40;
      }
    }
    &-address {
      color: $tuna-gray-80;
      font-size: 1rem;
      line-height: 28px;
      font-weight: 400;
    }
  }
  .c-office-page__hero-map {
    min-height: 459px;
  }
}
