.c-salary-info {
  &__title {
    font-size: 2rem;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
  }

  &__subtitle {
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }
  }
}

.text-highlight {
  color: #ff6600; /* Example color for the function title */
}

.c-salary-page {
  &__hero {
    &-intro-container {
      padding: 4rem 1rem 0rem;
      max-width: 1150px;
      margin-inline: auto;

      @include media-breakpoint-up(lg) {
        padding: 5rem 2rem 0rem;
      }
    }
  }

  &-benchmark {
    @include media-breakpoint-up(md) {
      padding-inline: 4rem;
    }

    @include media-breakpoint-up(lg) {
      padding-inline: 2rem;
    }

    @include media-breakpoint-up(lg) {
      padding-inline: 0;
    }

    &__figures {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 50px;

      @include media-breakpoint-up(sm) {
        gap: 77px;
      }

      @include media-breakpoint-up(lg) {
        gap: 140px;
      }

      &-low,
      &-high {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 35px;

        @include media-breakpoint-up(lg) {
          height: 60px;
          font-size: 22px;
        }
      }

      &-average {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 22px;
        align-items: center;
        height: 35px;

        @include media-breakpoint-up(lg) {
          height: 60px;
          font-size: 40px;
        }
      }

      &-label {
        text-align: center;
        font-size: 9px;
        color: #7f8594;

        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }
      }
    }

    &-image {
      display: flex;
      flex-direction: row;
      justify-content: center;

      border-bottom: solid 4px #eaeaea;
    }
  }
}
