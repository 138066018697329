.c-pagination {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  position: relative;
  color: $tuna-gray;
  &-next,
  &-last,
  &-first,
  &-prev {
    position: absolute;
  }
  &-first {
    left: -4rem;
  }
  &-prev {
    left: -2rem;
  }
  &-next {
    right: -2rem;
  }
  &-last {
    right: -4rem;
  }
  .page-link {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-item.active .page-link {
    font-weight: bold;
  }
  li a {
    color: $tuna-gray;
    font-weight: 400;
  }
}
