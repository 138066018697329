.c-navbar__subnav-banner {
  width: calc(((100%) / 5) + 8.25rem);
  min-height: 350px;
  img {
    max-width: 100%;
  }

  @include media-breakpoint-down(md) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(((100%) / 3) + 8.25rem);
    width: 100%;
    min-height: auto;
  }

  &__image {
    height: 60%;

    @include media-breakpoint-down(md) {
      height: 20vh;
    }
  }
}

.c-navbar__subnav-banner-textWrapper {
  padding: 20px 32px;
  @include media-breakpoint-up(lg) {
    padding-top: 25px;
    padding-bottom: 72px;
  }
  &:not(:last-of-type) {
    box-shadow: inset 0px -1px 0px #dde0e7;
  }

  &__title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  &__description {
    font-size: 0.75rem;
  }

  &__link {
    ::after {
      content: '\27F6';
      margin-left: 10px;
      display: inline-block;
    }
  }

  a {
    font-weight: 500;
    font-size: 0.875rem !important;
  }
}
