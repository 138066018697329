.c-resource-overview__resources,
.c-job-overview-two-panes__results--items {
  & .c-pagination {
    width: fit-content;
    max-width: 100%;
    gap: 0.25rem;
    margin: 0;

    & > .page-item {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2.25rem;
      height: 2.25rem;
      border-radius: 0.5rem;
      background-color: $slate-gray-10;

      @include media-breakpoint-up(xl) {
        min-width: 3.25rem;
        height: 3.25rem;
        border-radius: 0.86956rem;
      }

      &.active:not(.first):not(.last) {
        background-color: $tuna-gray-80;

        & > .page-link {
          color: $white;
          font-weight: 400;
        }
      }

      &.disabled {
        display: none !important;
      }

      & > .page-link {
        width: 100%;
        height: 100%;

        & > .visually-hidden {
          width: 0;
          height: 0;
        }
      }
    }

    & .c-pagination-next,
    .c-pagination-last,
    .c-pagination-first,
    .c-pagination-prev {
      position: static;
    }
  }
}
