.c-map {
  &-selected-info {
    padding: 1.5rem 2.5rem 1.5rem 1.5rem;
    max-width: 50%;
    position: absolute;
    background-color: white;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);

    &__address {
      color: $tuna-gray-80;
      font-size: 0.875rem;
    }

    &__close {
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.168 0 13 0zm0 2c6.086 0 11 4.914 11 11s-4.914 11-11 11S2 19.086 2 13 6.914 2 13 2zM9.219 7.78L7.78 9.22l3.782 3.78-3.782 3.782L9.22 18.22 13 14.437l3.781 3.782 1.438-1.438L14.437 13l3.782-3.781L16.78 7.78 13 11.562l-3.781-3.78z' fill='%23303642'/%3E%3C/svg%3E");
      height: 26px;
      width: 26px;
      border: 0;
      background-color: transparent;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    max-width: 30%;
  }
}
