.c-job-search-link {
  margin-bottom: 0;
  @include media-breakpoint-up(lg) {
    background-color: #e4003a;
    display: grid;
    padding-right: 0 !important;
    padding-left: 95px !important;
    grid-template-columns: 60% 1fr;
    padding-top: 7rem;
  }
  h1 {
    margin-bottom: 2.5rem;
    color: $tuna-gray;
    @include media-breakpoint-up(lg) {
      color: white;
    }
    a {
      text-decoration: underline;
    }
  }
  &__form {
    z-index: 2;
    background-color: #e4003a;
    margin-inline: -1.5rem;
    @include media-breakpoint-up(lg) {
      .homepage & {
        margin-bottom: -4rem;
      }
      background-color: white;

      box-shadow: 0px 0.5rem 1.5rem rgba(48, 54, 66, 0.16);
      border-radius: 0.5rem;
      padding: 2.5rem !important;
    }

    .input-group {
      .homepage & {
        margin-top: -2.5rem;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 2rem !important;
      }
      input {
        background-color: white;
        border-radius: 0.25rem;
      }
    }

    div[role='tablist'] {
      column-gap: 1rem;
      display: flex;
      button {
        background-color: $tuna-gray-20;
        border: none;
        border-radius: 0.5rem;
        color: $slate-gray-60;
        font-size: 1.125rem;
        line-height: 2rem;
        padding-inline: 1rem;
        padding-block: 0.5rem;

        svg {
          transform: rotate(180deg);
        }
        path {
          fill: $slate-gray-60;
        }
        &[data-headlessui-state='selected'] {
          filter: drop-shadow(0px 8px 24px rgba(48, 54, 66, 0.16));
          background-color: white;
          color: $tuna-gray;
          &:focus-visible {
            outline: none;
          }
          svg {
            transform: rotate(0);
          }
          path {
            fill: $yellow;
          }
          &::before {
            content: '';
            position: absolute;
            left: 0.5rem;
            bottom: -1.5rem;
            width: 0;
            height: 0;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid white;
            z-index: 1;
          }
        }
      }
    }
    div[role='tabpanel'],
    div[id^='headlessui-disclosure-panel-'] {
      background-color: white;
      display: grid;
      gap: 0.5rem;
      grid-template-columns: 1fr 1fr;
      box-shadow: 0px 0.5rem 1.5rem rgba(48, 54, 66, 0.16);
      border-radius: 0.25rem;
      padding-block: 1rem;
      padding-inline: 1rem;
      position: relative;
      font-size: 0.875rem;
      & > * {
        font-weight: normal;
        color: $tuna-gray-80;
        font-weight: 400;
        &:not(.no-border) {
          padding-bottom: 0.5rem;
          border-bottom: 1px solid $slate-gray-20;
        }
      }
      @include media-breakpoint-up(lg) {
        padding-inline: 1.5rem;
        font-size: inherit;
        border-radius: 0.5rem;
        column-gap: 1rem;
      }
    }
    button[type='submit'] {
      background-color: transparent;
      border: none;
      right: 1.25rem;
      top: 1.25rem;
      min-width: auto;
    }
    button[id^='headlessui-disclosure-button-'] {
      background: #f6f6f6;
      border: 1px solid #dde0e7;
      border-radius: 4px;
      padding-block: 0.5rem;
      padding-inline: 1rem;
      text-align: left;
      display: flex;
      align-items: center;
      color: $tuna-gray;
      svg {
        transform: rotate(180deg);
        margin-left: auto;
      }
      path {
        fill: $slate-gray-60;
      }
      &[data-headlessui-state='open'] {
        svg {
          transform: rotate(0);
        }
        path {
          fill: $yellow;
        }
      }
    }
  }
}
