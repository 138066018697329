.c-500-header {
  margin: 2rem 1rem;

  &__logo {
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__message-container {
    margin: 75px 0 50px;
    h3 {
      color: $tuna-gray;
      margin-bottom: 0.5rem;
      font-weight: bolder;
      margin-top: 1rem;
      font-size: 1.5rem;
      line-height: 1.1;
    }
    p {
      color: $tuna-gray-60;
    }
  }
  &__social {
    &--nav-item {
      &:first-child {
        margin-left: 0;
      }
      line-height: 2rem;
      font-size: 1.125rem;
      color: $purple-20;
      padding: 0 5px;
      display: block;
      margin: 5px;
    }
    &-icon {
      &--facebook:before {
        background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h11v-9h-3v-4h3V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763V11h4.44l-1 4h-3.44v9H22a2 2 0 002-2V2a2 2 0 00-2-2z' fill='%234267B2'/%3E%3C/svg%3E");
      }
      &--twitter:before {
        margin-top: 2px;
        background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24 2.6c-.9.4-1.8.7-2.8.8 1-.6 1.8-1.6 2.2-2.7-1 .6-2 1-3.1 1.2-.9-1-2.2-1.6-3.6-1.6-2.7 0-4.9 2.2-4.9 4.9 0 .4 0 .8.1 1.1-4.2-.2-7.8-2.2-10.2-5.2-.5.8-.7 1.6-.7 2.5 0 1.7.9 3.2 2.2 4.1-.8 0-1.6-.2-2.2-.6v.1c0 2.4 1.7 4.4 3.9 4.8-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 2 2.4 3.4 4.6 3.4-1.7 1.3-3.8 2.1-6.1 2.1-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.5 2.2 9.1 0 14-7.5 14-14v-.6c1-.7 1.8-1.6 2.5-2.5z' fill='%231DA1F2'/%3E%3C/svg%3E");
      }
      &--linkedin:before {
        background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 0H1C.4 0 0 .4 0 1v22c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM7.1 20.5H3.6V9h3.6v11.5h-.1zM5.3 7.4c-1.1 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zm15.2 13.1h-3.6v-5.6c0-1.3 0-3-1.8-3-1.9 0-2.1 1.4-2.1 2.9v5.7H9.4V9h3.4v1.6c.5-.9 1.6-1.8 3.4-1.8 3.6 0 4.3 2.4 4.3 5.5v6.2z' fill='%230077b5'/%3E%3C/svg%3E");
      }
      &::before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        background-repeat: no-repeat;
      }
    }
  }
}
