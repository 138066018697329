.c-spotlight {
  &__heading {
    margin-bottom: 64px;
  }

  &__card {
    margin-bottom: 32px;
    @include media-breakpoint-up(lg) {
      padding: 10px;
    }
    &-top-title {
      font-size: 16px;
      line-height: 26px;
      color: #7c7c7c;
    }
    &-title {
      font-size: 18px;
      line-height: 21px;
      color: $tuna-gray;
      max-width: 200px;
    }

    &:hover {
      box-shadow: 0 4px 4px rgb(190 185 185 / 25%);
    }
  }

  &__title-wrapper {
    padding-left: 20px;
  }

  &__image-wrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    @include media-breakpoint-up(md) {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}
