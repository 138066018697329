$section: '.c-event-overview';
$block: '.c-event-preview';

#{$section} {
  &__events {
    &-load-more {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath fill='none' d='M0 0h50v50H0z'/%3E%3Cpath fill='%23e94e0f' d='M41 17H31c-.6 0-1-.4-1-1s.4-1 1-1h8V7c0-.6.4-1 1-1s1 .4 1 1v10z'/%3E%3Cpath fill='%23e94e0f' d='M25 43c-9.9 0-18-8.1-18-18S15.1 7 25 7c5.1 0 10 2.2 13.4 5.9.7.7 1.3 1.5 1.8 2.3.3.5.2 1.1-.3 1.4s-1.1.2-1.4-.3c-.5-.7-1-1.4-1.6-2.1C33.9 10.9 29.5 9 25 9 16.2 9 9 16.2 9 25s7.2 16 16 16 16-7.2 16-16c0-.6.4-1 1-1s1 .4 1 1c0 9.9-8.1 18-18 18z'/%3E%3C/svg%3E");
        width: 60px;
        height: 60px;
        content: '';
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      background: $white;
      border: none;
      color: $orange;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}
