.employer-overview {
  box-shadow: 0px 16px 40px rgba(48, 54, 66, 0.16);

  .employer-overview__image {
    height: 216px;
  }
  .employer-overview__content {
    &__title {
      font-size: 1.5rem;
      line-height: 1.33;
    }
    &__description {
      overflow-y: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: break-spaces;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }

  a {
    line-height: 2rem;
  }
}
