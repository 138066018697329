.c-search-input {
  &:has(input:focus-visible) {
    border: 1px solid #dde0e7;
    box-shadow: 0px 8px 24px rgba(48, 54, 66, 0.24);
    background: white;
    outline: none;
    border-radius: 4px;
  }
  input {
    padding: 22px 15px 18px 20px;
    font-size: 18px;
    background-color: transparent;
    border: none;
    border: 1px solid #dde0e7;

    &:hover {
      outline-offset: 0;
      border: 1px solid $black;
    }
    &:focus-visible {
      outline: none;
      background: white;
      border-bottom: 0;
      border: none;
    }
  }
  &__location {
    padding-left: 60px !important;
  }
  &__extra-padding input {
    padding-left: 60px;
  }
  &:not(&__extra-padding) &__suggestions {
    span {
      transform: translateX(10px);
    }
  }
  &__suggestions {
    row-gap: 4px;
    list-style: none;
    padding-left: 0;
    box-shadow: 0px 8px 24px rgba(48, 54, 66, 0.24);
    background: white;
    margin-bottom: 0;
    padding-top: 0;
    li {
      font-size: 20px;
      line-height: 1.6;
      color: $tuna-gray-60;
      cursor: pointer;
    }
  }
}
