.c-language-switch {
  background-color: transparent;
  @include media-breakpoint-up(lg) {
    margin-left: 42px;
  }
}

.c-language-list {
  background: $white;
  box-shadow: 0px 8px 24px rgba(15, 17, 21, 0.1);
  top: 70px;
  right: 0;
  padding: 56px 38px;
  max-width: 300px;

  max-height: calc(100vh - 70px);
  overflow-y: auto;

  @include media-breakpoint-up(lg) {
    max-width: 831px;
    max-height: unset;
    height: auto;
  }

  &__backdrop {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    top: 0;
    left: 0;
    cursor: default;
  }

  &__title {
    font-size: 24px;
    line-height: 24px;
    color: #6d6e70;
    margin-bottom: 33px;
  }

  &__cta {
    span {
      margin-left: 12px;
      display: inline-flex;
      @include media-breakpoint-up(lg) {
        margin-left: 24px;
      }
    }
  }

  &__list {
    @include media-breakpoint-up(lg) {
      column-count: 2;
    }

    li {
      padding: 16px 0;
      border-bottom: 1px solid #dedede;
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
        padding: 0;
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__name {
    font-size: 16px;
    line-height: 20px;
    color: $tuna-gray-80;
  }

  &__close {
    top: 0;
    left: 0;
    background-color: $purple;
    border: none;
    width: 34px;
    height: 34px;
  }
}
